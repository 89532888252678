<template>
  <div>
    <div class="vx-row mb-base">
      <div class="vx-col w-full md:w-1/3">
        <gs-statistics-card-count
          :fetch-data-function="fetchCountSMSCampaignsFilters"
          :title="$t('SentCampaigns')"
          icon="SendIcon"
          color="success"
          :fetch-filters="sentFilter"
          :fetch-filters-match="$enums.AppFilterMathType.ALL"/>
      </div>
      <div class="vx-col w-full mt-3 md:mt-0 md:w-1/3">
        <gs-statistics-card-count
          :fetch-data-function="fetchCountSMSCampaignsFilters"
          :title="$t('ScheduledCampaigns')"
          icon="ClockIcon"
          color="primary"
          :fetch-filters="scheduledFilter"
          :fetch-filters-match="$enums.AppFilterMathType.ALL"/>
      </div>
      <div class="vx-col w-full mt-3 md:mt-0  md:w-1/3">
        <gs-statistics-card-count
          :fetch-data-function="fetchCountSMSCampaignsFilters"
          :title="$t('DraftCampaigns')"
          icon="EditIcon"
          color="warning"
          :fetch-filters="draftsFilter"
          :fetch-filters-match="$enums.AppFilterMathType.ALL"/>
      </div>
    </div>

    <vx-card
      :title="$t('$DashboardAnalyticsPage.CampaignsAnalyticsCardTitle')"
      collapse-action>
      <gs-line-chart
        :fetch-data-function="fetchAnalyticsSmsCampaigns"
        :series-info="smsCampaignsSeriesInfo"
        :y-axis-title="$tc('$General.Message', 2)"
        :no-data-title="$t('$DashboardAnalyticsPage.CampaignsLineChartsNoDataTitle')"
        :no-data-subtitle="$t('$DashboardAnalyticsPage.CampaignsLineChartsNoDataSubtitle')"/>
    </vx-card>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

// Components
import GsLineChart from '@/views/charts-and-maps/charts/GsLineChart.vue';
import GsStatisticsCardCount from '@/views/charts-and-maps/charts/GsStatisticsCardCount.vue';

// Others
import themeConfig from '../../../themeConfig';

/**
 * Campaign analytics
 *
 * @module views/analytics/CampaignsAnalytics
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} smsCampaignsSeriesInfo - sms campaign series info
 */
export default {
  name: 'CampaignsAnalytics',
  i18n: {
    messages: {
      en: {
        SentCampaigns: 'Sent campaigns',
        ScheduledCampaigns: 'Scheduled campaigns',
        DraftCampaigns: 'Draft campaigns',
      },
    },
  },
  components: {
    GsLineChart,
    GsStatisticsCardCount,
  },
  data() {
    return {
      smsCampaignsSeriesInfo: [
        {
          name: this.$t('$General.Outbound'),
          key: 'outbound',
          color: themeConfig.colors.success,
        },
        {
          name: this.$t('$General.Bounced'),
          key: 'bounced',
          color: themeConfig.colors.danger,
        },
        {
          name: this.$t('$General.Inbound'),
          key: 'inbound',
          color: themeConfig.colors.primary,
        },
      ],
      sentFilter: {
        status: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.EQUALS,
          filter: this.$enums.Campaign.Status.COMPLETED,
        },
      },
      scheduledFilter: {
        status: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.EQUALS,
          filter: this.$enums.Campaign.Status.PENDING,
        },
      },
      draftsFilter: {
        status: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.EQUALS,
          filter: this.$enums.Campaign.Status.DRAFT,
        },
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAnalyticsSmsCampaigns: 'smsCampaign/fetchAnalytics',
      fetchCountSMSCampaigns: 'smsCampaign/fetchCountSMSCampaigns',
      fetchCountSMSCampaignsFilters: 'smsCampaign/fetchCountSMSCampaignsFilters',
    }),
  },
};
</script>
